
import React from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';

function TemperatureDetailContainer({ time, selectedDeviceSelectedDayData }) {

    return (
        <DetailContainer
            name={"Temperature"}
            // value={`${(selectedDeviceSelectedDayData.length === 1 && Object.keys(selectedDeviceSelectedDayData[0]).length !== 0 && selectedDeviceSelectedDayData[0]?.temperatureLogs?.length !== 0)
            //     ? selectedDeviceSelectedDayData[0].temperatureLogs[
            //         selectedDeviceSelectedDayData[0].temperatureLogs.length - 1
            //     ].t.toFixed(2)
            //     : 0
            //     }°C`}


            value={`${selectedDeviceSelectedDayData?.[0]?.temperatureLogs?.slice(-1)?.[0]?.t.toFixed(2) || 0}°C`}
            time={time}
            color={"#FDECEC"}
            borderColor={"#EF4444"}
            image={images.temperature}
        />
    )
}

export default TemperatureDetailContainer