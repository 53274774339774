
import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { todayDataReducer, todayDataOfDeviceReducer } from './reducer/TodayData'
import { loginDetailReducer } from './reducer/login'
import { loadMultipleDataReducer } from './reducer/totalData'
import { houseDataReducer } from './reducer/house'
import { selectedDayReducer } from './reducer/selectedDate'
import { dropdownSelectionData } from './reducer/deviceDropdownSelection'
import { unitSnapshotData } from './reducer/dailyUnit'
import { housesListReducer } from './reducer/housesList'
import { schedules } from './reducer/schedule'
import unitReportReducer from './reducer/report'
import { loadMultipleHouseData } from './reducer/collectiveHouse'

const persistConfig = {
    key: 'persist-key',
    storage,
    blacklist:['unitReport']
}

const persistedReducer = persistReducer(persistConfig, combineReducers({
    todayData: todayDataReducer,
    todayData2: todayDataOfDeviceReducer,
    login: loginDetailReducer,
    multipleData: loadMultipleDataReducer,
    houseData: houseDataReducer,
    selectedDate: selectedDayReducer,
    dropdownSelectionData: dropdownSelectionData,
    snapshotUnit: unitSnapshotData,
    housesList:housesListReducer,
    schedules:schedules,
    unitReport:unitReportReducer,
    mutipleHouse:loadMultipleHouseData,
}))

let store = createStore(persistedReducer);
let persistor = persistStore(store)
export default store;
export { persistor }