import moment from 'moment'
import React from 'react'
import { Col } from 'react-bootstrap'

function DetailContainer({name,value,time,color,borderColor,image,OnClick=()=>{},styleValue={}}) {
    const styles = {
        container: {
            
            borderRadius: '14px',
            background: '#FFFFFF',
            padding: '24px',
        },
        smallContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        leftContainer: {
            borderLeft: `3px solid ${borderColor}`,
            paddingLeft: '17px',
            display:'flex',
            flexDirection: "column",
            alignItems:'flex-start',
            gap:'5px',
        },
        name: {
            color: "#A6AAB0",
            fontFamily: "manrope",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "24px",
        },
        value: {
            color: "#363B3F",
            fontFamily: "manrope",
            fontSize: "25px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "24px", /* 96% */
        },
        iconContainer: {
            height: '41px',
            width: '41px',
            borderRadius: "7px",
            background: color,
            padding: "5px",
        },
        logText: {
            color: "#A7AAB1",
            fontFamily: "manrope",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
        },
        logTime: {
            color: "#2050FF",
            fontFamily: "manrope",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
        }
    }
    return (
        <Col md={3} sm={12}>
          <div style={{...styles.container}}>
            <div style={{ ...styles.smallContainer }}>
                <div style={{ ...styles.leftContainer }}>
                    <div style={{ ...styles.name }}>
                        {name}
                    </div>
                    <div 
                      style={{ ...styles.value , ...styleValue}}
                      onClick={()=>OnClick()}
                    >
                        {value}
                    </div>
                </div>
                <div style={{
                    ...styles.iconContainer,
                   
                }}>
                     <img
                src={image}
                style={{maxHeight:'100%',maxWidth:'100%'}}
              />
                </div>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap:'7px',
                marginTop:'10px',
            
            }}>
                <span
                    style={{
                        height: "9px",
                        width: '9px',
                        borderRadius: '100%',
                        background: '#31F338',
                        display: 'inline-block'
                    }}></span>
                <span style={{
                    ...styles.logText
                }}>
                    Last log
                </span>
                <span style={{...styles.logTime}}>
                    {new Date(time).toLocaleString('en-us',{
                   hour: 'numeric',
                   minute: 'numeric',})}
                </span>
            </div>
            </div>
        </Col>
    )
}

export default DetailContainer