import store from "..";
import axios from "axios"
import { loginDetails } from "../actionTypes"
import { fetchAllHouses, fetchHouseDetails } from "./house";
import { selectedDay } from "./selectedDate";
import { fetchAndStoreMultipleDevicesTodayData } from "./totalData";
import { selectedHouse as selectedHouseFromDropdown, selectedRoom as selectedRoomFromDropdown, selectedRoomDevices, selectedDeviceFromDropdown } from '../../redux/action/deviceDropdownSelection';
export const loginDetail = (data) => {
    return {
        type: loginDetails.LOGINUSER,
        payload: data
    }
}

export const loginUserPhone = async (phoneNumber, password) => {
    try {
        const response = await axios.post(
            'https://subscriptioncloud.alistetechnologies.com/v2/auth/login',
            {
                mobile: `+91${phoneNumber}`,
                password,
                deviceToken: '',
            }
        );

        if (response.status === 200) {
            const { data } = response.data;

            const res = await fetchHouseDetails(data.profile.selectedHouse, phoneNumber);

            const houseData = res?.data

            console.log(houseData, 'houseData')

            await fetchAllHouses(phoneNumber)
            store.dispatch(loginDetail(data));

            const date = new Date().setHours(0, 0, 0, 0)
            store.dispatch(selectedDay(date))

            // const houseData = store.getState().houseData;

            // if (Object.keys(houseData).length === 0) return;
            store.dispatch(selectedHouseFromDropdown(houseData._id))

            const roomsWithNonEmptyEnergySync = houseData?.rooms?.filter(room => room.energiSync.length > 0);
            if (roomsWithNonEmptyEnergySync.length === 0) return;
            // setRoomCount(roomsWithNonEmptyEnergySync.length)
            const defaultRoom = roomsWithNonEmptyEnergySync[0]._id;
            const defaultRoomAllDevices = roomsWithNonEmptyEnergySync[0].energiSync;

            const defaultDeviceDetails = defaultRoomAllDevices[0];

            store.dispatch(selectedRoomFromDropdown(defaultRoom));
            store.dispatch(selectedRoomDevices(defaultRoomAllDevices));
            store.dispatch(selectedDeviceFromDropdown(defaultDeviceDetails));

            console.log(defaultDeviceDetails, 'defaultDeviceDetails')

            console.log(date, 'date')
            // if (date=== '') { 

            //   console.log('hello')
            //   store.dispatch(selectedDay(new Date().setHours(0, 0, 0, 0))) }

            // setLoading(true);
            try {
                await fetchAndStoreMultipleDevicesTodayData([{ deviceId: defaultDeviceDetails.deviceId }]);
            } catch (error) {
                console.error('Failed to fetch and store device data:', error);
            }
            // setLoading(false);

            return { success: true, message: 'Sign in successful' };
        } else {
            return { success: false, message: 'Authentication Error' };
        }
    } catch (error) {
        let message = error.message;

        if (error.response && error.response.data) {
            message = error.response.data.message;
        }

        return { success: false, message };
    }
};