
import React from 'react'
import DetailContainer from '../MainPage/common/DetailContainer'
import { images } from '../common/images';
import { messages } from '../common/notification';
import { deviceControl } from '../common/ApiCall';

function ACStatusDetailContainer({ time, selectedDeviceSelectedDayData, selectedDate, deviceId }) {

  if (selectedDeviceSelectedDayData.length == 0) return;

  const isConnected = selectedDeviceSelectedDayData[0].connected;
  const hasStateLogs = selectedDeviceSelectedDayData[0].stateLogs?.length > 0;
  let isLastLogOff = true

  if (hasStateLogs) {
    isLastLogOff = selectedDeviceSelectedDayData[0].stateLogs.at(-1).s === 0;
  }

  const isDifferentDate = (new Date(selectedDate).setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0))

  const value = isDifferentDate || !isConnected || !hasStateLogs ? "Offline" : (isLastLogOff ? "Off" : "On")

  return (
    <DetailContainer
      name={"Appliance Status"}
      value={value}

      OnClick={() => {
        if (selectedDeviceSelectedDayData.length === 1) {
          if (new Date(selectedDate).setHours(0, 0, 0, 0) !==
            new Date().setHours(0, 0, 0, 0)) {
            // messages.success("Date is not toady")
            return
          }
          if (!isConnected) {
            messages.notify('Device is Offline')
            return
          }
          if (hasStateLogs && !isLastLogOff) {
            console.log('hello')
            deviceControl(deviceId, 0)
          } else {
            deviceControl(deviceId, 100)
          }
        }
      }}
      styleValue={{ cursor: value === 'Offline' ? '' : 'pointer' }}
      time={time}
      color={"rgba(118, 190, 194, 0.10)"}
      borderColor={"#76BEC2"}
      image={images.ac}
    />
  )
}

export default ACStatusDetailContainer