export const resetState = {
    RESET:'RESET_STATE'
}
export const toadyData = {
    LOAD: 'LOAD',
    REMOVE_LOAD: 'REMOVE_LOAD',
    LOADTWO: 'LOADTWO',
    REMOVE_LOADTWO: 'REMOVE_LOADTWO'
}
export const loginDetails = {
    LOGINUSER: 'LOGINUSER'
}

export const collectiveData = {
    LOAD: 'MULTIPLE_LOAD'
}

export const houseData = {
    LOADHOUSE: 'LOADHOUSE',
    ADD:'ADD_HOUSE',
}

export const housesList = {
    LOADHOUSE_DATA: 'LOADHOUSE_DATA'
}

export const selectedDate = {
    LOAD_SELECTED_DATE: 'LOAD_SELECTED_DATE'
}

export const selectedHouseData = {
    LOAD_SELECTED_HOUSE_DATA: 'LOAD_SELECTED_HOUSE_DATA'
}

export const selectedRoomData = {
    LOAD_SELECTED_ROOM_DATA: 'LOAD_SELECTED_ROOM_DATA'
}

export const selectedRoomAllDevices = {
    LOAD_SELECTED_ROOM_DEVICES: 'LOAD_SELECTED_ROOM_DEVICES'
}

export const selectedDeviceData = {
    LOAD_SELECTED_DEVICE_DATA: 'LOAD_SELECTED_DEVICE_DATA'
}

export const clearDropdownData = {
    CLEAR_DATA: 'CLEAR_DATA'
}

export const snapshotDailyUnit = {
    LOAD: "LOAD_DAILY_UNIT_SNAPSHOT",
    // UPDATE:"UPDATE_DAILY_UNIT_SNAPSHOT",
}

export const scheduleType = {
    CREATE:'SCHEDULE_CREATE',
    UPDATE:'SCHEDULE_UPDATE',
    FETCH:'SCHEDULE_FETCH',
    REMOVE:'SCHEDULE_REMOVE',
    ENABLE:'SCHEDULE_ENABLE',
    ALLREMOVE:'ALL_SCHEDULE_REMOVE',

}
export const reportTypes ={
    ADD_UNIT:'ADD_UNIT',
    UPDATE_CALL_COUNT:'UPDATE_CALL_COUNT',
}