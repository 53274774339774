import { schedules } from "./component/redux/reducer/schedule"

export const serverUrl={
    aws:'https://pyd0xvkp5g.execute-api.ap-south-1.amazonaws.com/default',
    web:'https://web.alistetechnologies.com',
    sub:'https://subscriptioncloud.alistetechnologies.com'
}

export const pages = {
    schedulePage:'schedule'
}